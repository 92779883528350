.certificates-component-cont{
    padding: 6% 4%;
    background-image: url("../../../public/images/wd.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
.certificates-component-cont h2{
    padding-bottom: 3%;
    text-align: center;
    font-size: 70px;
    font-weight: 800;
}
.certificate-container{
    height: 70vh;
    cursor: pointer;
}
.certificate-slider-container{
   
}
.mySwiper{
    width: 90vw;
}
.certificate-img-container{
    height: 90%;
    text-align: center;
    /* padding: 10% 10%; */
}
.certificate-img{
    height: 100%;
    width: 100%;
    object-fit: contain;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* border-radius: 0px 25px 25px 0px; */
}

.certificate-btn-container{
    display: flex;
    gap: 1%;
    padding: 3% 1%;
}
.certificate-btn{
    background-color: #25befb;
    color: white;
    border: 1px solid #25befb;
    padding: .5% 1%;
}
.certificate-btn:hover{
    background-color: #1b7aa0;
}

.clicked_btn{
    background-color: #1b7aa0;
}

@media only screen and (max-width: 700px) {
    
    .certificates-component-cont h2{
       
        font-size: 50px;
        font-weight: 600;
    }

}