.bottom-buttons{
    position: fixed;
    bottom: 3%;
    background-color: transparent;
    font-size: large;
    width: 100%;
}


.bottom-buttons-container{
    display: flex;
    justify-content: center;

}

.round-btn{
    background-color: rgb(58, 116, 196);
    color: white;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-large;
    z-index: 100000000000;
    cursor: pointer;

}

.back{
    position: fixed;
    left: 2%;
    bottom: 4%;
}
.up{
    position: fixed;
    bottom: 0;

    opacity: 0;
    cursor:auto;
    transition: all 0.3s ease-in-out;
}
.show-btn{
    cursor: pointer;
    transform: translateY(-50%);
    opacity: 1;
}