.products-page-container{
    padding: 6% 8%;
    background-image: url("../../../public/images/wd.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

}
.products-page-container > h3{
    text-align: center;
    padding-bottom: 3%;
    font-weight: 550;
    font-size: 70px;
}

.products-item{
    
    /* width: 31.5%; */
    padding: 0.5%;
    /* margin: 0.5%; */
    
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.products-item-inner{

    width: 100%;
    height: 100%;
    padding: 10%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;

    background-color: rgb(13,110,146);
    border: 1px solid rgb(37, 150, 190);
}

.products-item-inner:hover{
    
    background-color: #165a72;
    color: white;

}

.products-item-logo{
    font-size: 50px;
}

.products-item-heading{
    padding: 3%;
    font-size: larger;
    width: 100%;
}


/*  */

.products-page-product-container{
    padding: 3% 8%;
    background-image: url("../../../public/images/wd.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
.products-page-product-container h3{
    margin-bottom: 8%;
    font-weight: 550;
    text-align: center;
}
.products-page-product{
    display: flex;
    gap: 5%;
    /* color: rgb(68, 64, 64); */
}

.product-page-img-container{
    height: 40%;
    width: 45%;
}
.product-page-img{
    height: 100%;
    width: 100%;
  
    object-fit: cover;
}

.product-page-content-container{
    font-size: x-large;
    width: 55%;
}

.product-page-content-container li{
    padding: 1% 0;
    list-style-type: disc;
}
.product-page-content-container ul{
    padding: 1% 0 1% 2rem;
}

@media only screen and (max-width: 950px) {

    .products-item-logo{
        font-size: 40px;
    }
    .products-item-heading{
        font-size: 18px;
    }

}
@media only screen and (max-width: 950px) {

    .products-item-logo{
        font-size: 30px;
    }
    .products-item-heading{
        font-size: 14px;
    }

}
@media only screen and (max-width: 850px) {

    .products-page-container{
        padding: 4% 5%;
    }
    
    .products-page-product{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .product-page-img-container{
        height: 30rem;
        width: 100%;
    }
    .product-page-content-container{
        width: 100%;
    }

}

@media only screen and (max-width: 768px) {

    .product-last-two{
        padding: 5% 5%;
    }

}
@media only screen and (max-width: 580px) {
.products-item{
    width: 70.5%!important;
    margin: 0 auto;
}
  
}
@media screen and (max-width: 500px) {
    .products-page-container > h3{
        font-size: 40px;
    }
    .products-page-product-container h3{
        font-size: 30px;
    }
}