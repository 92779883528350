.Client{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 28vw; */
    
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
}

.client-img{
    height: 13rem;
    width: 13rem;
    object-fit: contain;
}

.client-heading{
    font-size: 35px;
    font-weight: 500;
}

@media screen and (max-width: 1050px) {
    .client-heading{
        font-size: 25px;
    }
}