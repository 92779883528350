.Navbar{
    font-size: x-large;    
    width: 100%;
    font-size: x-large;
    font-weight: 500;
    padding: 0.3% 3%;
    justify-content: center!important;
}
#logo{
    height: 5rem;
    /* width: 5rem; */
    object-fit: cover;
}

.Navli{
    color: white!important;
}
.dropdown-item:hover{
    background-color: black!important;
}
@media (max-width: 1259px) {
    #navbarNavDropdown > ul{
        display: flex;
        gap: 0rem!important;
    }
}
@media (max-width: 992px) {
    .Navbar{
        justify-content: space-between!important;
    }
    .Navbar > div{
        justify-content: space-between!important;
        margin-left: 1rem!important;
    }
    .Navbar > a{
        margin-left: 1rem!important;
    }
}