

.service-section-container{
    
    padding: 3%;

    background-image: linear-gradient(rgb(13, 110, 146,0.8),rgb(13, 110, 146,0.8)), url('../../../public/images/ConstructionWorker2.jpg') ;
    background-size: 100% 100%;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    justify-content: center;
    align-items: center;
}
 
.s-section-container-in{

    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(6,1fr);

    row-gap: 2rem;
    column-gap: 0.5rem;

}

.icon-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    padding: 1%;
    color: white;
}

.icon-container i{
    font-size: 70px;
    transition: transform 100ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-container i:hover{
    transform: translateY(-20%);
}

.s-section-heading{
    text-align: center;
    font-size: larger;
    color:white;
    padding-top: 1%;
    padding-bottom: 3.5%;
}

.icon-container div{
    font-size: x-large;
    font-weight: 500;
}

/* responsive  */

@media only screen and (max-width: 992px) {
    
    .icon-container i{
        font-size: 50px;
        
    }
    .icon-container div{
        font-size: larger;
        font-weight: 500;
    }
    
}
@media only screen and (max-width: 768px) {
    
    .icon-container i{
        font-size: 40px;
        
    }
    .icon-container div{
        font-size: large;
        font-weight: 450;
    }
    .service-section-container{
        
    }
   
}
@media only screen and (max-width: 658px) {
    
    .service-section-container{
        padding: 4%;
    }

    .icon-container i{
        font-size: 30px;
        
    }
    .icon-container div{
        font-size: medium;
        font-weight: 425;
    }
    
    
}
@media only screen and (max-width: 558px) {
    
    .icon-container div{
        font-size: small;
        font-weight: 400;
    }

    .s-section-container-in{

        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: repeat(4,1fr);
    }

    #secondRow{
        grid-row-start: 2/3;
    }
    #thirdRow{
        grid-row-start: 3/4;
    }
    
}




